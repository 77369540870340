export const AboutUsitems = [
    { label: 'WHO WE ARE', path: '/about-us/who-we-are/' },
    { label: 'OUR MANAGEMENT', path: '/about-us/our-management/' },
    { label: 'CORPORATE GOVERNANCE', path: '/about-us/corporate-governance/' },
    // { label: 'NEWS & EXHIBITIONS', path: '/about-us/news-exhibitions/' },
    { label: 'QUALITY POLICY', path: '/about-us/quality-policy/' },
    { label: 'HISTORY', path: '/about-us/history/' },

];

export const CoolingSolutions = [
    {
        label: 'DRY COOLING SYSTEMS',
        path: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/',
        subItems: [
            { label: 'Air Cooled Condensers', path: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/' },
            { label: 'Classic Air Cooled Condenser', isSpacer: true, path: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/classic-air-cooled-condensers/' },
            { label: 'Induced Draft Air Cooled Condenser - InAir', isSpacer: true, path: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/in-air/' },
            { label: 'Aluminium Finned Steel Tube System | ALEX-System', isSpacer: true, path: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/alex-system/' },
            { label: 'Hybrid Cooling Deluge ACC', isSpacer: true, path: '/cooling-solutions/dry-cooling-systems/air-cooled-condensers/hybrid-cooling-deluge-acc/' },

        ]
    },

    {
        label: 'WET COOLING TOWERS',
        path: '/cooling-solutions/wet-cooling-towers',
        subItems: [
            { label: 'Natural Draft Cooling Towers', path: '/cooling-solutions/wet-cooling-towers/NDCT/' },
            { label: 'Induced Draft Cooling Towers', path: '/cooling-solutions/wet-cooling-towers/IDCT/' },
            {
                label: 'Cooling Tower Components', path: '/cooling-solutions/wet-cooling-towers/cooling-tower-components/'
            },
            { label: 'NF 20/NET 150 Modular Splash Fill', isSpacer: true, path: '/cooling-solutions/wet-cooling-towers/cooling-tower-components/NET-150-modular-splash-fill/' },
            { label: 'Film Fill - Cross - Fluted Type', isSpacer: true, path: '/cooling-solutions/wet-cooling-towers/cooling-tower-components/film-fill-cross-fluted-type/' },
            { label: 'Drift Eliminators', isSpacer: true, path: '/cooling-solutions/wet-cooling-towers/cooling-tower-components/drift-eliminator/' },
            { label: 'Spray Nozzles', isSpacer: true, path: '/cooling-solutions/wet-cooling-towers/cooling-tower-components/spray-nozzles/' },

        ]
    }

];


export const AfterSalesAndServices = [

    { label: 'DRY & WET COOLING SERVICES', path: '/after-sales-and-services/dry-wet-cooling-services/' },
    { label: 'PAC - COMBINED DRY - WET COOLING SYSTEMS', path: '/after-sales-and-services/combined-dry-wet-cooling-systems/' },
    { label: 'CONVERSION OF WCC - ACC', path: '/after-sales-and-services/wcc-acc/' },
    { label: 'AIR COOLED CONDENSER REBUNDLING', path: '/after-sales-and-services/air-cooled-condenser-rebundling/' },

];

export const contactin = [
    { label: "CONTACT FORM", path: '/contact/', },  
    // { label: <strong><a href="tel:08045342857"><b>TollFree Number:</b> 080-453-42857</a></strong> , value: '08045342857', },  
];
